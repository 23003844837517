
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as admin0eiku7Jj1aMeta } from "/app/pages/admin.vue?macro=true";
import { default as dashboardJu37u7k0toMeta } from "/app/pages/dashboard.vue?macro=true";
import { default as _91id_93Pn7Q7gtBo7Meta } from "/app/pages/files/[id].vue?macro=true";
import { default as indexaxRBkpZK3hMeta } from "/app/pages/files/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as index62VVgTK5ZLMeta } from "/app/pages/matches/[id]/index.vue?macro=true";
import { default as pointsGonipn0KAGMeta } from "/app/pages/matches/[id]/points.vue?macro=true";
import { default as serviceDtYl2irVFKMeta } from "/app/pages/matches/[id]/service.vue?macro=true";
import { default as indexreavjmKP6OMeta } from "/app/pages/matches/index.vue?macro=true";
import { default as _91id_93Emaaq0veoMMeta } from "/app/pages/players/[id].vue?macro=true";
import { default as indexYLLnNOWxVjMeta } from "/app/pages/players/index.vue?macro=true";
import { default as editbUooxexoBEMeta } from "/app/pages/profile/edit.vue?macro=true";
import { default as indexaeouN7Kzh7Meta } from "/app/pages/profile/index.vue?macro=true";
import { default as registermu8R7rSP5uMeta } from "/app/pages/register.vue?macro=true";
import { default as _91id_93R8oVTxpoMMMeta } from "/app/pages/sandbox/[id].vue?macro=true";
import { default as indexHMczZc2BKkMeta } from "/app/pages/sandbox/index.vue?macro=true";
import { default as primevueXhNxl3l0TxMeta } from "/app/pages/sandbox/primevue.vue?macro=true";
import { default as signupezBbZGXKU6Meta } from "/app/pages/signup.vue?macro=true";
import { default as tokenHm95qqbX8gMeta } from "/app/pages/token.vue?macro=true";
import { default as uploadelBuHMr4HhMeta } from "/app/pages/upload.vue?macro=true";
import { default as indexnq54o81oozMeta } from "/app/pages/users/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/app/pages/about.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/app/pages/admin.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/app/pages/dashboard.vue")
  },
  {
    name: "files-id",
    path: "/files/:id()",
    meta: _91id_93Pn7Q7gtBo7Meta || {},
    component: () => import("/app/pages/files/[id].vue")
  },
  {
    name: "files",
    path: "/files",
    component: () => import("/app/pages/files/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "matches-id",
    path: "/matches/:id()",
    meta: index62VVgTK5ZLMeta || {},
    component: () => import("/app/pages/matches/[id]/index.vue")
  },
  {
    name: "matches-id-points",
    path: "/matches/:id()/points",
    meta: pointsGonipn0KAGMeta || {},
    component: () => import("/app/pages/matches/[id]/points.vue")
  },
  {
    name: "matches-id-service",
    path: "/matches/:id()/service",
    meta: serviceDtYl2irVFKMeta || {},
    component: () => import("/app/pages/matches/[id]/service.vue")
  },
  {
    name: "matches",
    path: "/matches",
    component: () => import("/app/pages/matches/index.vue")
  },
  {
    name: "players-id",
    path: "/players/:id()",
    meta: _91id_93Emaaq0veoMMeta || {},
    component: () => import("/app/pages/players/[id].vue")
  },
  {
    name: "players",
    path: "/players",
    component: () => import("/app/pages/players/index.vue")
  },
  {
    name: "profile-edit",
    path: "/profile/edit",
    component: () => import("/app/pages/profile/edit.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexaeouN7Kzh7Meta || {},
    component: () => import("/app/pages/profile/index.vue")
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/app/pages/register.vue")
  },
  {
    name: "sandbox-id",
    path: "/sandbox/:id()",
    meta: _91id_93R8oVTxpoMMMeta || {},
    component: () => import("/app/pages/sandbox/[id].vue")
  },
  {
    name: "sandbox",
    path: "/sandbox",
    component: () => import("/app/pages/sandbox/index.vue")
  },
  {
    name: "sandbox-primevue",
    path: "/sandbox/primevue",
    component: () => import("/app/pages/sandbox/primevue.vue")
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("/app/pages/signup.vue")
  },
  {
    name: "token",
    path: "/token",
    meta: tokenHm95qqbX8gMeta || {},
    component: () => import("/app/pages/token.vue")
  },
  {
    name: "upload",
    path: "/upload",
    component: () => import("/app/pages/upload.vue")
  },
  {
    name: "users",
    path: "/users",
    meta: indexnq54o81oozMeta || {},
    component: () => import("/app/pages/users/index.vue")
  }
]